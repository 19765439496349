import Vue from 'vue';

import PieChart from './shared/charts/PieChart.vue'
import DoughnutChart from './shared/charts/DoughnutChart.vue'
import BarChart from './shared/charts/BarChart.vue'

import MasterPage from "./shared/MasterPage.vue";
import TextSearch from "./shared/TextSearch.vue";
import Avatar from "./shared/Avatar.vue";
import AlterarSenha from "./shared/AlterarSenha.vue";
import RelatorioPage from "./shared/RelatorioPage.vue";
import EditalCard from "./shared/EditalCard.vue";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

import CadastroAvaliarProjeto from "./geral/avaliarprojeto/Cadastro.vue";
import CadastroAvaliarProjetoPersonalizado from "./geral/avaliarprojeto/CadastroPersonalizado.vue";

import CadastroEdital from "./geral/edital/Cadastro.vue";
import CadastroRelacaoDocumento from "./geral/relacaoDocumento/Cadastro.vue";
import CadastroAreaAtuacao from "./geral/areaAtuacao/Cadastro.vue";
import CadastroEmpresaGrupo from "./geral/empresaGrupo/Cadastro.vue";
import CadastroNatureza from "./geral/natureza/Cadastro.vue";
import CadastroBairroComunidade from "./geral/bairroComunidade/Cadastro.vue";
import CadastroCriterioAvaliacao from "./geral/criterioAvaliacao/Cadastro.vue";

import CadastroProjeto from "./ong/projeto/Cadastro.vue";
import CadastroProjetoPersonalizavel from "./ong/projeto/CadastroPersonalizavel.vue";
import ProjetoPersonalizavelResposta from "./ong/projeto/ProjetoResposta.vue";

import CadastroProjetoEquipe from "./ong/projeto/CadastroEquipe.vue";
import CadastroProjetoIndicador from "./ong/projeto/CadastroIndicador.vue"
import CadastroProjetoComunicacao from "./ong/projeto/CadastroComunicacao.vue"
import CadastroProjetoParceiro from "./ong/projeto/CadastroParceiro.vue"
import CadastroProjetoDocumento from "./ong/projeto/CadastroDocumento.vue"
import CadastroProjetoOrcamento from "./ong/projeto/CadastroOrcamento.vue"
import CadastroProjetoOrcamentoItem from "./ong/projeto/CadastroOrcamentoItem.vue"

import CadastroUsuario from "./auxiliar/usuario/Cadastro.vue";

Vue.component('pie-chart', PieChart);
Vue.component('doughnut-chart', DoughnutChart);
Vue.component('bar-chart', BarChart);

Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("avatar-component", Avatar);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("relatorio-page", RelatorioPage);
Vue.component("edital-card", EditalCard);
Vue.component("vue-pdf-app", VuePdfApp);

Vue.component("cadastro-avaliarprojeto", CadastroAvaliarProjeto);
Vue.component("cadastro-avaliarprojeto-personalizado", CadastroAvaliarProjetoPersonalizado);

Vue.component("cadastro-edital", CadastroEdital);
Vue.component("cadastro-relacaoDocumento", CadastroRelacaoDocumento);
Vue.component("cadastro-areaAtuacao", CadastroAreaAtuacao);
Vue.component("cadastro-empresaGrupo", CadastroEmpresaGrupo);
Vue.component("cadastro-natureza", CadastroNatureza);
Vue.component("cadastro-bairroComunidade", CadastroBairroComunidade);
Vue.component("cadastro-criterioAvaliacao", CadastroCriterioAvaliacao);

Vue.component("cadastro-projeto", CadastroProjeto);
Vue.component("cadastro-projeto-personalizavel", CadastroProjetoPersonalizavel);
Vue.component("projeto-personalizavel-resposta", ProjetoPersonalizavelResposta);
Vue.component("cadastro-projeto-equipe", CadastroProjetoEquipe);
Vue.component("cadastro-projeto-indicador", CadastroProjetoIndicador);
Vue.component("cadastro-projeto-comunicacao", CadastroProjetoComunicacao);
Vue.component("cadastro-projeto-parceiro", CadastroProjetoParceiro);
Vue.component("cadastro-projeto-documento", CadastroProjetoDocumento);
Vue.component("cadastro-projeto-orcamento", CadastroProjetoOrcamento);
Vue.component("cadastro-projeto-orcamento-item", CadastroProjetoOrcamentoItem);

Vue.component("cadastro-usuario", CadastroUsuario);
