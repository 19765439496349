import { Projeto } from ".";

export class ProjetoComunicacao{

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto;
    objetivoComunicacao: string = "";
    publicoInteresse: string = "";
    estrategias: string = "";
    midia: string = "";
    quantidade: number = 0;
    periodo: string = "";

    constructor(model?: ProjetoComunicacao){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.objetivoComunicacao = model.objetivoComunicacao;
        this.publicoInteresse = model.publicoInteresse;
        this.estrategias = model.estrategias;
        this.midia = model.midia;
        this.quantidade = model.quantidade;
        this.periodo = model.periodo;
    }
}