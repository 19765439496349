import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class OngService extends Service{

  constructor(){
    super('ong');
  }

  ByEditalProjeto(){
    return http.get(`${this.GetNomeControle()}/byeditalprojeto`);
  }

}