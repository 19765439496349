<template>    
    <canvas :id="id"/>
</template>

<script>
import { Chart, registerables } from 'chart.js'

export default {
    props:{
        id: {
            required: true
        },
        data: {
            required: true
        },
        headers: {
            required: true
        }
    },
    created(){
        Chart.register(...registerables);
    },
    mounted(){
        this.Generate();
        this.UpdateChart(); 
    },
    watch:{
        data: function (){
            this.UpdateChart();
        }
    },
    data: () => ({
        chart: {}
    }),
    methods:{
        Generate(){
            const canvas = document.getElementById(this.id);
            const config = {
                type: 'pie',
                options: {     
                    aspectRatio: 2,   
                    plugins: {                        
                        legend: {                                      
                            position: 'left',
                            labels: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black',
                                font:{
                                    size: 11
                                }
                            }
                        }
                    }                    
                }
            }
            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){
            if(this.chart){
                this.chart.data.datasets.length = 0;
                this.chart.data.datasets = [
                    {
                        label: this.title,
                        data: this.data,
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.3)',
                            'rgba(255, 99, 132, 0.3)',
                            'rgba(135, 206, 235, 0.3)',
                            'rgba(205, 133, 63, 0.3)',
                            'rgba(128, 128, 128, 0.3)',
                            'rgba(0, 139, 139, 0.3)',
                            'rgba(154, 205, 50, 0.3)',
                            'rgba(54, 162, 235, 0.3)',
                            'rgba(244, 164, 96, 0.3)',
                            'rgba(0, 128, 0, 0.3)',
                            'rgba(255, 206, 86, 0.3)',
                            'rgba(70, 130, 180, 0.3)',
                            'rgba(218, 165, 32, 0.3)',
                            'rgba(153, 102, 255, 0.3)',
                        ],
                        borderWidth: 1
                    }
                ];
                this.chart.data.labels = this.headers,
                this.chart.update();
            }
        }
    }
}
</script>