import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const RequireAuth = (to: any, next: NavigationGuardNext<Vue>, perfilPermitido: number[]) => {
  
  var sessionApp: any;

  if(localStorage.sessionApp){
    sessionApp = JSON.parse(localStorage.sessionApp);
    if(perfilPermitido.length > 0){
      if(perfilPermitido.find(x => x == sessionApp.dados.perfilId)){
        next();
      }
      else{
        next({ name:"Home" });
      }
    }
    else{
      next();
    }
  }
  else{
    next({ name: 'login' });
  }

}

const routes: Array<RouteConfig> = [
  { path: '*', redirect: '/home'},
  { path: '/login', name: 'Login', component: () => import('@/views/Login.vue') },
  { path: '/home', name: 'Home', component: () => import('@/views/Home.vue') },

  // GERAL
  { path: '/edital', name: 'Edital', component: () => import('@/views/geral/edital/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/edital/tipodocumento', name: 'RelacaoDocumento (Edital)', component: () => import('@/views/geral/relacaoDocumento/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/edital/areaatuacao', name: 'AreaAtuacao', component: () => import('@/views/geral/areaAtuacao/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/edital/empresagrupo', name: 'EmpresasGrupo', component: () => import('@/views/geral/empresaGrupo/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/edital/natureza', name: 'NaturezaOrcamentaria', component: () => import('@/views/geral/natureza/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/edital/bairrocomunidade', name: 'BairroComunidades', component: () => import('@/views/geral/bairroComunidade/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/edital/criterio', name: 'CriterioAvaliacao', component: () => import('@/views/geral/criterioAvaliacao/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/projeto', name: 'AvaliarProjeto', component: () => import('@/views/geral/avaliarProjeto/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1, 2])},
  //MESMA TELA UTILIZADA PARA APROVAÇÃO E CONSULTA
  { path: '/projeto/aprovacao', name: 'Aprovacao', component: () => import('@/views/geral/projetoAprovacao/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/projeto/avaliacao', name: 'Avaliacao', component: () => import('@/views/geral/projetoAprovacao/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1, 2])},
  { path: '/projeto/avaliacao/detalhar/:id', name: 'AvaliacaoDetalhar', component: () => import('@/views/geral/projetoAprovacao/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1, 2])},

  // PRESTACAO CONTAS
  { path: '/relatorio/projetoanalitico', name: 'ProjetoAnalitico', component: () => import('@/views/relatorio/projetoAnalitico/Configuracao.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/relatorio/projetoanalitico/detalhar/:id', name: 'DetalharProjetoAnalitico', component: () => import('@/views/relatorio/projetoAnalitico/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [])},
  { path: '/relatorio/projetoanaliticopersonalizado/detalhar/:id', name: 'DetalharProjetoAnaliticoPersonalizado', component: () => import('@/views/relatorio/projetoAnalitico/DetalharPersonalizado.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [])},
  
  { path: '/relatorio/acompanhamentofinanceiro', name: 'AcompanhamentoFinanceiro', component: () => import('@/views/relatorio/acompanhamentoFinanceiro/Configuracao.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/relatorio/acompanhamentofinanceiro/detalhar/:id', name: 'DetalharAcompanhamentoFinanceiro', component: () => import('@/views/relatorio/acompanhamentoFinanceiro/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/relatorio/fluxobancario', name: 'FluxoBancario', component: () => import('@/views/relatorio/fluxoBancario/Configuracao.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/relatorio/fluxobancario/detalhar', name: 'DetalharFluxoBancario', component: () => import('@/views/relatorio/fluxoBancario/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/relatorio/desembolso', name: 'Desembolso', component: () => import('@/views/relatorio/desembolso/Configuracao.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/relatorio/desembolso/detalhar/:id', name: 'DetalharDesembolso', component: () => import('@/views/relatorio/desembolso/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},

  // MONITORAMENTO
  { path: '/relatorio/indicador', name: 'Indicador', component: () => import('@/views/relatorio/indicador/Configuracao.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/relatorio/indicador/detalhar/:id', name: 'DetalharIndicador', component: () => import('@/views/relatorio/indicador/Detalhar.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},

  //ONG
  { path: '/ong/projeto', name: 'Projeto', component: () => import('@/views/ong/projeto/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [])},

  // AUXILIAR
  { path: '/auxiliar/usuario', name: 'Usuário', component: () => import('@/views/auxiliar/usuario/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
  { path: '/auxiliar/empresa', name: 'Empresa', component: () => import('@/views/auxiliar/empresa/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [1])},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
