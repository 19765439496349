import { Edital, PublicoAlvo } from ".";

export class EditalPublicoAlvo{

    editalId: number = 0;
    edital!: Edital;
    publicoAlvoId: number = 0;
    publicoAlvo!: PublicoAlvo;

    constructor(model?: EditalPublicoAlvo){

        if(!model)
            return;

        this.editalId = model.editalId;
        this.edital = model.edital;
        this.publicoAlvoId = model.publicoAlvoId;
        this.publicoAlvo = model.publicoAlvo;
    }
}