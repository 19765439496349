import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';

export class PageBase extends Vue {

    public sessionApp: any;

    public app: {empresaId?: number, empresa: string, ongId?:number, ong: string, perfilId?: number, login: string, email: string, temaEscuro: boolean, usuarioFoto: any, usuarioId: number, tenantLogo: any} = {
        empresaId: undefined,
        empresa: '',
        ongId: undefined,
        ong: '',
        perfilId: undefined,
        login: '',
        email: '',
        temaEscuro: false,
        usuarioFoto: null,
        usuarioId: 0,
        tenantLogo: null
    };

    constructor(){
        super();

        if (!localStorage.sessionApp){
            this.$router.push({ name: 'Login' });
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.empresaId = this.sessionApp.dados.empresaId;
        this.app.empresa = this.sessionApp.dados.empresa;
        this.app.ongId = this.sessionApp.dados.ongId;
        this.app.ong = this.sessionApp.dados.ong;
        this.app.perfilId = this.sessionApp.dados.perfilId;
        this.app.login = this.sessionApp.dados.login;
        this.app.email = this.sessionApp.dados.email;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.usuarioId = this.sessionApp.dados.usuarioId;
        this.app.tenantLogo = this.sessionApp.dados.tenantLogo;

    }

    ExportarExcel(tabela: string, worksheet?: string){
      TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    AbrirLink(url: string){
        window.open(url, 'blank')?.focus();
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }
}