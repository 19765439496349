import { EditalCampoValor, EditalFormulario } from ".";

export class EditalCampo{

    id: number = 0;
    editalFormularioId: number = 0;
    editalFormulario!: EditalFormulario;
    label: string = "Novo Campo";
    texto: string = "";
    tipoId: number = 0;

    valores: EditalCampoValor[] = [];

    constructor(model?: EditalCampo){

        if(!model)
            return;

        this.id = model.id;
        this.editalFormularioId = model.editalFormularioId;
        this.editalFormulario = model.editalFormulario;
        this.label = model.label;
        this.texto = model.texto;
        this.tipoId = model.tipoId;

        this.valores = model.valores;
    }
}