import { EditalCampo } from ".";

export class EditalCampoValor{

    id: number = 0;
    editalCampoId: number = 0;
    editalCampo!: EditalCampo;
    valor: string = "";

    constructor(model?: EditalCampoValor){

        if(!model)
            return;

        this.id = model.id;
        this.editalCampoId = model.editalCampoId;
        this.editalCampo = model.editalCampo;
        this.valor = model.valor;
    }
}