import { http } from "@/core/ApiConfig";
import { ProjetoResposta } from "@/core/models/geral";
import { Service } from "../shared/Service";

export class ProjetoService extends Service{

  constructor(){
    super('projeto');
  }

  private MontaFiltro(parametros: string, filter: any){
    if(filter){
      let paramTemp = '';

      if(filter.instituicaoId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `ongId in (${filter.instituicaoId})`;
      }

      if(filter.editalId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `editalId in (${filter.editalId})`;
      }

      if(filter.grupoId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `grupoId in (${filter.grupoId})`;
      }

      if(filter.nome){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(nome, '${filter.nome}')`;
      }

      if(filter.statusId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `status in (${filter.statusId})`;
      }

      if (paramTemp) {
        
        if (parametros) {
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }
    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.MontaFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  AvaliarProjeto(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.MontaFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}/AvaliarProjeto${parametros}`, this.GetHeader(include));
  }

  GetByEditalId(editalId: number, ongId: number, dataInicial: any, dataFinal: any){
    return http.get(`${this.GetNomeControle()}/Edital/${editalId}/Ong/${ongId}`, {
      params:{
        dataInicial,
        dataFinal
      }
    });
  }

  Orcamentos(id: number, dataInicial?: string, dataFinal?: string){
    return http.get(`${this.GetNomeControle()}/${id}/Orcamentos`, {
      params: {
        dataInicial,
        dataFinal
      }
    });
  }

  OrcamentosRaw(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/OrcamentosRaw`);
  }
  
  IndicadoresRaw(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/IndicadoresRaw`);
  }

  ParceirosRaw(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/ParceirosRaw`);
  }

  ObterCompletoIndicador(id: number, empresaId: number, dataInicial?: string, dataFinal?: string){
    return http.get(`${this.GetNomeControle()}/${id}/ObterCompletoIndicador`, {
      params: {
        empresaId,
        dataInicial,
        dataFinal
      }
    });
  }

  //USADO NO ACOMPANHAMENTO FINANCEIRO
  Completo(id: number, ongId: number, dataInicial: string, dataFinal: string){
    return http.get(`${this.GetNomeControle()}/${id}/AcompanhamentoFinanceiro`, {
      params:{
        id,
        ongId,
        dataInicial,
        dataFinal
      }
    })
  }

  Parecer(id: number, parecer: string){
    return http.post(`${this.GetNomeControle()}/${id}/Parecer`, undefined, {
      params: {
        parecer
      }
    });
  }

  AlterarStatus(id: number, status: number){
    return http.post(`${this.GetNomeControle()}/${id}/AlterarStatus/${status}`);
  }

  Enviar(id: number){
    return http.post(`${this.GetNomeControle()}/${id}/Enviar`);
  }

  // RESPOSTAS PERSOLANIZADAS
  ObterResposta(id: number, campoId: number){
    return http.get(`${this.GetNomeControle()}/${id}/Resposta/${campoId}`);
  }
  Responder(model: ProjetoResposta){
    return http.post(`${this.GetNomeControle()}/Responder`, model);
  }
  ExcluirResposta(respostaId: number){
    return http.delete(`${this.GetNomeControle()}/Resposta/${respostaId}`);
  }
  Quantitativo(){
    return http.get(`${this.GetNomeControle()}/Quantitativo`);
  }
  //GED
  AdicionarRespostaArquivo(resposta: ProjetoResposta, nome: string, arquivo: any){
    let bodyFormData = new FormData();
    bodyFormData.append("resposta", JSON.stringify(resposta));
    bodyFormData.append('arquivo', new Blob([arquivo], {type: 'application/pdf'}));

    return http.post(`${this.GetNomeControle()}/resposta/documento`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      params: {
        nome
      }
    })
  }
  DownloadRespostaArquivo(respostaId: number, nome: string,){
    return http.get(`${this.GetNomeControle()}/resposta/${respostaId}/documento`, {
      responseType: 'blob',
      params:{
        nome
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    })
  }
  //

  //DASHBOARD
  ObterProjetoPorStatus(dataInicio?: string, dataTermino?: string, editalId?: number, grupoId?: number){
    return http.get(`${this.GetNomeControle()}/ProjetoStatus`, {
      params:{
        dataInicio,
        dataTermino,
        editalId,
        grupoId
      }
    });
  }
  ObterProjetoPorAreaAtuacao(dataInicio?: string, dataTermino?: string, editalId?: number, grupoId?: number){
    return http.get(`${this.GetNomeControle()}/ObterProjetoPorAreaAtuacao`, {
      params:{
        dataInicio,
        dataTermino,
        editalId,
        grupoId
      }
    });
  }
  ObterProjetoPorAreaAtuacaoValor(dataInicio?: string, dataTermino?: string, editalId?: number, grupoId?: number){
    return http.get(`${this.GetNomeControle()}/ObterProjetoPorAreaAtuacaoValor`, {
      params:{
        dataInicio,
        dataTermino,
        editalId,
        grupoId
      }
    });
  }
  ObterProjetoPorResumo(dataInicio?: string, dataTermino?: string, editalId?: number, grupoId?: number){
    return http.get(`${this.GetNomeControle()}/ObterProjetoPorResumo`, {
      params:{
        dataInicio,
        dataTermino,
        editalId,
        grupoId
      }
    });
  }
  ObterProjetoPorBeneficiario(dataInicio?: string, dataTermino?: string, editalId?: number, grupoId?: number){
    return http.get(`${this.GetNomeControle()}/ObterProjetoPorBeneficiario`, {
      params:{
        dataInicio,
        dataTermino,
        editalId,
        grupoId
      }
    });
  }
  ObterProjetoPorBairroComunidade(dataInicio?: string, dataTermino?: string, editalId?: number, grupoId?: number){
    return http.get(`${this.GetNomeControle()}/ObterProjetoPorBairroComunidade`, {
      params:{
        dataInicio,
        dataTermino,
        editalId,
        grupoId
      }
    });
  }

  //GED
  EnviarDocumento(id: number, nome: string, tipoDocumentoId: number, arquivo: any){
    let bodyFormData = new FormData();    
    bodyFormData.append('arquivo', new Blob([arquivo], {type: 'application/pdf'}));

    return http.post(`${this.GetNomeControle()}/${id}/documento`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data' },
      params: {
        nome,
        tipoDocumentoId
      }
    })
  }
  DownloadDocumento(id: number, nome: string){
    return http.get(`${this.GetNomeControle()}/${id}/documento`, {
      responseType: 'blob',
      params:{
        nome
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    })
  }
  DeletarDocumento(id: number, documentoId, nome: string){
    return http.delete(`${this.GetNomeControle()}/${id}/documento/${documentoId}`, {
      responseType: 'blob',
      params:{
        nome        
      }
    })
  }

  //Indicador
  DownloadDocumentoIndicador(id: number, ongId: number, nome: string){
    return http.get(`${this.GetNomeControle()}/IndicadorDocumento/${id}/${ongId}`, {
      responseType: 'blob',
      params:{
        nome
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    })
  }
  //Indicador
  DownloadDocumentoCompromisso(id: number, ongId: number, nome: string){
    return http.get(`${this.GetNomeControle()}/CompromissoDocumento/${id}/${ongId}`, {
      params:{
        nome
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    })
  }
}