import { Empresa, Estado, Municipio } from ".";

export class BairroComunidade{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    municipioId!: number;
    municipio!: Municipio;
    estadoId!: number;
    estado!: Estado;
    nome: string = "";
    situacao: string = "A";
    visivel: string = 'S';

    constructor(model?: BairroComunidade) {
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.municipioId = model.municipioId;
        this.municipio = model.municipio;
        this.estadoId = model.estadoId;
        this.estado = model.estado;
        this.nome = model.nome;
        this.situacao = model.situacao;
        this.visivel = model.visivel;
    }
}