import { Natureza, ProjetoOrcamento } from ".";

export class ProjetoOrcamentoItem{

    id: number = 0;
    orcamentoId: number = 0;
    orcamento!: ProjetoOrcamento;
    naturezaId: number = 0;
    natureza!: Natureza;
    dataInicio?: string;
    dataTermino?: string;
    totalEstimado: number = 0;
    descricao: string = "";

    paiIndex: number = 0;

    constructor(model?: ProjetoOrcamentoItem){

        if(!model)
            return;

        this.id = model.id;
        this.orcamentoId = model.orcamentoId;
        this.orcamento = model.orcamento;
        this.naturezaId = model.naturezaId;
        this.natureza = model.natureza;
        this.dataInicio = model.dataInicio ? model.dataInicio.toDateYYYYMMDD() : '';
        this.dataTermino = model.dataTermino ? model.dataTermino.toDateYYYYMMDD() : '';
        this.totalEstimado = model.totalEstimado;
        this.descricao = model.descricao;
    }
}