import { Projeto } from ".";

export class ProjetoIndicador{

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto;
    acao: string = "";
    resultados: string = "";
    indicadores: string = "";
    meiosVerificacao: string = "";
    periodoVerificacao: string = "";

    constructor(model?: ProjetoIndicador){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.acao = model.acao;
        this.resultados = model.resultados;
        this.indicadores = model.indicadores;
        this.meiosVerificacao = model.meiosVerificacao;
        this.periodoVerificacao = model.periodoVerificacao;
    }
}