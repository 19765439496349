import { Projeto } from ".";

export class ProjetoEquipe{

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto;
    nome: string = "";
    funcao: string = "";
    formacaoExperiencia: string = "";
    cargaHoraria: string = "";
    formaContratacao: number = 0;

    constructor(model?: ProjetoEquipe){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.nome = model.nome;
        this.funcao = model.funcao;
        this.formacaoExperiencia = model.formacaoExperiencia;
        this.cargaHoraria = model.cargaHoraria;
        this.formaContratacao = model.formaContratacao;
    }
}