import { Projeto, ProjetoOrcamentoItem } from ".";

export class ProjetoOrcamento{

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto;
    descricao: string = "";
    dataInicio?: string;
    dataTermino?: string;
    
    itens: ProjetoOrcamentoItem[] = [];

    valorTotal: number = 0;

    constructor(model?: ProjetoOrcamento){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.descricao = model.descricao;
        this.dataInicio = model.dataInicio ? model.dataInicio.toDateYYYYMMDD() : '';
        this.dataTermino = model.dataTermino ? model.dataTermino.toDateYYYYMMDD() : '';

        this.itens = model.itens;

        this.valorTotal = model.valorTotal;
    }
}