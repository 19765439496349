import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class BairroComunidadeService extends Service{

  constructor(){
    super('bairroComunidade');
  }

  private MontaFiltro(parametros: string, filter: any){
    if(filter){
      let paramTemp = '';

      if(filter.visivel){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(visivel, '${filter.visivel ? 'S' : 'N'}')`;
      }

      if (paramTemp) {
        
        if (parametros) {
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }
    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.MontaFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  public ObterEstados() {
    return http.get(`${this.GetNomeControle()}/Estados`);
  }

  public ObterMunicipios(estadoId: number) {
    return http.get(`${this.GetNomeControle()}/Estado/${estadoId}/Municipios`);
  }

  public ObterBairrosComunidades(estadoId: number, municipioId: number, visivel: string){
    return http.get(`${this.GetNomeControle()}/Estado/${estadoId}/Municipio/${municipioId}`, {
      params: {
        visivel
      }
    });
  }
}