import { http } from "@/core/ApiConfig";

export class EnumService{

    private _nomeControle: string = '';

    constructor(nomeControle){
        this._nomeControle = nomeControle;
    }

    Listar(){
        return http.get(`${this._nomeControle}`);
    }

}