import { Projeto } from ".";

export class ProjetoParceiro{

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto
    nome: string = "";
    tipoContribuicao: number = 0;
    valor: number = 0;

    constructor(model?: ProjetoParceiro){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.nome = model.nome;
        this.tipoContribuicao = model.tipoContribuicao;
        this.valor = model.valor;
    }
}