import Vue from 'vue';
import Vuetify, { colors } from 'vuetify/lib';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    options:{
      customProperties: true
    },
    dark: false,
    default: 'light',
    themes: { 
      light: {
        primary: '#4A70A8',
        secondary: '#DD6625',
        click: '#4A70A8',
        toolbar: '#ECEFF1',
        expandDataTable: '#d9d9d9',
        itemActive: colors.blue.lighten5,
        itemNeutral: colors.shades.white,
        cardBackground: '#ffffff',
        cardHomeBackground: '#ffffff',
        stickyTitle: '#FFFFFF',
        imageBackground: '#F3F3F3',
        principalBackground: '#F4F6F9',
        chipGreen: colors.green.lighten5,
        chipFontGreen: colors.green.lighten2,
        chipRed: colors.red.lighten5,
        chipFontRed: colors.red.lighten2,
        chipBlue: colors.blue.lighten5,
        chipFontBlue: colors.blue.lighten2,
        chipYellow: colors.yellow.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.darken2
      },
      dark: {
        primary: '#22406e',
        secondary: '#DD6625',
        click: '#ffffff',
        toolbar: '#4F4F4F',
        expandDataTable: '#545454',
        itemActive: colors.grey.lighten2,
        itemNeutral: colors.grey.darken4,
        cardBackground: '#525252',
        cardHomeBackground: '#2c2c2c',
        stickyTitle: '#1E1E1E',
        imageBackground: '#414141',
        principalBackground: '#525252',
        chipGreen: colors.green.lighten3,
        chipFontGreen: colors.green.darken2,
        chipRed: colors.red.lighten3,
        chipFontRed: colors.red,
        chipBlue: colors.blue.lighten3,
        chipFontBlue: colors.blue.accent2,
        chipYellow: colors.amber.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.darken2
      }
    },
  }
});
