import { BairroComunidade, Edital, Incentivo } from ".";

export class EditalBairroComunidade{

    editalId: number = 0;
    edital!: Edital;
    bairroComunidadeId: number = 0;
    bairroComunidade!: BairroComunidade;

    constructor(model?: EditalBairroComunidade){

        if(!model)
            return;

        this.editalId = model.editalId;
        this.edital = model.edital;
        this.bairroComunidadeId = model.bairroComunidadeId;
        this.bairroComunidade = model.bairroComunidade;
    }
}