import { EditalAreaAtuacao, EditalBairroComunidade, EditalTipoDocumento, EditalIncentivo, Empresa, EditalPublicoAlvo, EditalEmpreendedor, EditalCampo, EditalFormulario } from ".";

export class Edital{

    id: number = 0;
    empresaId: number = 0;
    numero: number = 0;
    descricao: string = "";
    dataInicio: string = "";
    dataFim: string = "";
    regulamento: string = "";
    logo: any = null;
    banner: any = null;
    descricao2: string = "";
    linkInscricao: string = "";
    status: number = 1;
    tipo: string = 'D';

    isBeneficiarioPadrao: boolean = false;
    isEquipePadrao: boolean = false;
    isIndicadorPadrao: boolean = false;
    isOrcamentoPadrao: boolean = false;
    
    areaAtuacoes: EditalAreaAtuacao[] = [];
    bairroComunidades: EditalBairroComunidade[] = [];
    formularios: EditalFormulario[] = [];
    empreendedores: EditalEmpreendedor[] = [];
    documentos: EditalTipoDocumento[] = [];
    incentivos: EditalIncentivo[] = [];
    publicoAlvos: EditalPublicoAlvo[] = [];

    constructor(model?: Edital){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.descricao = model.descricao;
        this.dataInicio = model.dataInicio;
        this.dataFim = model.dataFim;
        this.regulamento = model.regulamento;
        this.logo = model.logo;
        this.banner = model.banner;
        this.descricao2 = model.descricao2;
        this.linkInscricao = model.linkInscricao;
        this.status = model.status;
        this.tipo = model.tipo;

        this.isBeneficiarioPadrao = model.isBeneficiarioPadrao;
        this.isEquipePadrao = model.isEquipePadrao;
        this.isIndicadorPadrao = model.isIndicadorPadrao;
        this.isOrcamentoPadrao = model.isOrcamentoPadrao;
        
        this.areaAtuacoes = model.areaAtuacoes;
        this.bairroComunidades = model.bairroComunidades;
        this.formularios = model.formularios;
        this.documentos = model.documentos;
        this.empreendedores = model.empreendedores;
        this.incentivos = model.incentivos;
        this.publicoAlvos = model.publicoAlvos;
    }
}