import { Edital, EditalCampo } from ".";

export class EditalFormulario{

    id: number = 0;
    editalId: number = 0;
    edital!: Edital;
    nome: string = "Novo Formulário";

    campos: EditalCampo[] = [];

    constructor(model?: EditalFormulario){

        if(!model)
            return;

        this.id = model.id;
        this.editalId = model.editalId;
        this.edital = model.edital;
        this.nome = model.nome;

        this.campos = model.campos;
    }
}