import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class FluxoBancarioService extends Service{

  constructor(){
    super('FluxoBancario');
  }

  ObterCompletoNormal(empresaId: number, bancoId?: number, dataInicio?: string, dataTermino?: string){
    return http.get(`${this.GetNomeControle()}/ObterCompletoNormal`, {
      params:{
        empresaId,
        bancoId,
        dataInicio,
        dataTermino
      }
    });
  }

  Extrato(empresaId: number, projetoId: number, bancoId?: number, dataInicio?: string, dataTermino?: string){
    return http.get(`${this.GetNomeControle()}/Extrato`, {
      params:{
        empresaId,
        projetoId,
        bancoId,
        dataInicio,
        dataTermino
      }
    });
  }

  //GED
  DownloadDocumento(ongId: number, id: number, nome: string){
    return http.get(`${this.GetNomeControle()}/${id}/Documento/${ongId}`, {
      // responseType: 'blob',
      params:{
        nome
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    })
  }
}