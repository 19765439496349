import { Edital, Incentivo } from ".";

export class EditalIncentivo{

    editalId: number = 0;
    edital!: Edital;
    incentivoId: number = 0;
    incenvito!: Incentivo;

    constructor(model?: EditalIncentivo){

        if(!model)
            return;

        this.editalId = model.editalId;
        this.edital = model.edital;
        this.incentivoId = model.incentivoId;
        this.incenvito = model.incenvito;
    }
}