import { Edital, TipoDocumento } from ".";

export class EditalTipoDocumento{

    editalId: number = 0;
    edital!: Edital;
    tipoDocumentoId: number = 0;
    tipoDocumento!: TipoDocumento;

    constructor(model?: EditalTipoDocumento){

        if(!model)
            return;

        this.editalId = model.editalId;
        this.edital = model.edital;
        this.tipoDocumentoId = model.tipoDocumentoId;
        this.tipoDocumento = model.tipoDocumento;
    }
}