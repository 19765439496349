import { Projeto, TipoDocumento } from ".";

export class ProjetoDocumento {

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto;
    tipoDocumentoId: number = 0;
    tipoDocumento!: TipoDocumento;
    nome: string = "";
    arquivo: any = null;

    excluir: boolean = false;

    constructor(model?: ProjetoDocumento){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.tipoDocumentoId = model.tipoDocumentoId;
        this.tipoDocumento = model.tipoDocumento;
        this.nome = model.nome;
    }
}