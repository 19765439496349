export class Usuario{

    id: number = 0;
    nome: string = "";
    senha: string = "";
    email: string = "";
    dataHoraCriacao!: string;
    situacao: string = 'A';
    perfilId: number = 0;
    foto: any = null

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.senha = model.senha;
        this.email = model.email;
        this.dataHoraCriacao = model.dataHoraCriacao.toDateTimeDDMMYYYY();
        this.situacao = model.situacao;
        this.perfilId = model.perfilId;
        this.foto = model.foto;
    }
}