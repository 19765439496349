import { Projeto, ProjetoAvaliacaoItem, Usuario } from ".";

export class ProjetoAvaliacao{

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto;
    usuarioId: number = 0;
    usuario!: Usuario;
    comentario: string = "";
    veredito?: number;

    itens: ProjetoAvaliacaoItem[] = [];

    constructor(model?: ProjetoAvaliacao){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.comentario = model.comentario;
        this.veredito = model.veredito;

        this.itens = model.itens;
    }
}
