export class Ong {

    id: number = 0;
    nome: string = "";
    fantasia: string = "";
    cnpj: string = "";
    insc_Estadual: string = "";
    insc_Municipal: string = "";
    cep: string = "";
    logradouro: string = "";
    bairro: string = "";
    cidade: string = "";
    estado: string = "";
    pais: string = "";
    telefone1: string = "";
    telefone2: string = "";
    fax: string = "";
    email: string = "";
    responsavel: string = "";
    responsavelTel: string = "";
    responsavelEmail: string = "";
    plano: string = "";
    vencimento: string = "";
    situacao: string = "";

    constructor(model?: Ong){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.fantasia = model.fantasia;
        this.cnpj = model.cnpj;
        this.insc_Estadual = model.insc_Estadual;
        this.insc_Municipal = model.insc_Municipal;
        this.cep = model.cep;
        this.logradouro = model.logradouro;
        this.bairro = model.bairro;
        this.cidade = model.cidade;
        this.estado = model.estado;
        this.pais = model.pais;
        this.telefone1 = model.telefone1;
        this.telefone2 = model.telefone2;
        this.fax = model.fax;
        this.email = model.email;
        this.responsavel = model.responsavel;
        this.responsavelTel = model.responsavelTel;
        this.responsavelEmail = model.responsavelEmail;
        this.plano = model.plano;
        this.vencimento = this.vencimento ? this.vencimento.toDateTimeDDMMYYYY() : "";
        this.situacao = model.situacao;
    }
}