import { EditalCampo, EditalCampoValor, Projeto } from ".";

export class ProjetoResposta{

    id: number = 0;
    projetoId: number = 0;
    projeto!: Projeto
    campoId: number = 0;
    campo!: EditalCampo;
    valor?: string;
    valorNumerico?: number;
    valorCampoId?: number;
    valorCampo!: EditalCampoValor;

    constructor(model?: ProjetoResposta){

        if(!model)
            return;

        this.id = model.id;
        this.projetoId = model.projetoId;
        this.projeto = model.projeto;
        this.campoId = model.campoId;
        this.campo = model.campo;
        this.valor = model.valor;
        this.valorNumerico = model.valorNumerico;
        this.valorCampoId = model.valorCampoId;
        this.valorCampo = model.valorCampo;
    }
}