import { AreaAtuacao, BairroComunidade, Edital, Grupo, ProjetoAvaliacao, ProjetoComunicacao, ProjetoDocumento, ProjetoEquipe, ProjetoIndicador, ProjetoOrcamento, ProjetoParceiro, ProjetoResposta } from ".";

export class Projeto{

    id: number = 0;
    ongId: number = 0;
    status: number = 1;
    passoAtual: number = 1;
    objetoSocialEstatuto: string = "";
    suaHistoria: string = "";
    isFuncionarioPublico: any = 0;
    orgaoFuncionarioPublico: string = "";
    isLeiIncentivo: boolean = false;
    leiIncentivo: any = null;
    nome: string = "";
    problemaSolucionar: string = "";
    qualSeuSonho: string = "";
    metodologiaUtilizada: string = "";
    duracaoMeses: number = 0;
    perfilBeneficiario: string = "";
    isTrabalhaDeficiencia: any = 0;
    faixaEtaria0A4: number = 0;
    faixaEtaria5A9: number = 0;
    faixaEtaria10A14: number = 0;
    faixaEtaria15A19: number = 0;
    faixaEtaria20A24: number = 0;
    faixaEtaria25A60: number = 0;
    faixaEtaria60: number = 0;
    objetivo: string = "";
    video: string = "";
    editalId: number = 0;
    edital!: Edital;
    grupoId?: number;
    grupo!: Grupo;
    areaAtuacaoId?: number;
    areaAtuacao!: AreaAtuacao;
    bairroComunidadeId?: number;
    bairroComunidade!: BairroComunidade;
    projetoOngId: number = 0;
    parecer: string = "";

    avaliacoes: ProjetoAvaliacao[] = [];
    comunicacoes: ProjetoComunicacao[] = [];
    documentos: ProjetoDocumento[] = [];
    equipes: ProjetoEquipe[] = [];
    indicadores: ProjetoIndicador[] = [];
    orcamentos: ProjetoOrcamento[] = [];
    parceiros: ProjetoParceiro[] = [];
    respostas: ProjetoResposta[] = [];

    constructor(model?: Projeto){

        if(!model)
            return;

        this.id = model.id;
        this.ongId = model.ongId;
        this.status = model.status;
        this.passoAtual = model.passoAtual;
        this.objetoSocialEstatuto = model.objetoSocialEstatuto;
        this.suaHistoria = model.suaHistoria;
        this.isFuncionarioPublico = model.isFuncionarioPublico;
        this.orgaoFuncionarioPublico = model.orgaoFuncionarioPublico;
        this.suaHistoria = model.suaHistoria;
        this.isFuncionarioPublico = model.isFuncionarioPublico;
        this.orgaoFuncionarioPublico = model.orgaoFuncionarioPublico;
        this.isLeiIncentivo = model.isLeiIncentivo;
        this.leiIncentivo = model.leiIncentivo;
        this.nome = model.nome;
        this.problemaSolucionar = model.problemaSolucionar;
        this.qualSeuSonho = model.qualSeuSonho;
        this.metodologiaUtilizada = model.metodologiaUtilizada;
        this.duracaoMeses = model.duracaoMeses;
        this.perfilBeneficiario = model.perfilBeneficiario;
        this.isTrabalhaDeficiencia = model.isTrabalhaDeficiencia;
        this.faixaEtaria0A4 = model.faixaEtaria0A4;
        this.faixaEtaria5A9 = model.faixaEtaria5A9;
        this.faixaEtaria10A14 = model.faixaEtaria10A14;
        this.faixaEtaria15A19 = model.faixaEtaria15A19;
        this.faixaEtaria20A24 = model.faixaEtaria20A24;
        this.faixaEtaria25A60 = model.faixaEtaria25A60;
        this.faixaEtaria60 = model.faixaEtaria60;
        this.objetivo = model.objetivo;
        this.video = model.video;
        this.editalId = model.editalId;
        this.edital = model.edital;
        this.grupoId = model.grupoId;
        this.grupo = model.grupo;
        this.areaAtuacaoId = model.areaAtuacaoId;
        this.areaAtuacao = model.areaAtuacao;
        this.bairroComunidadeId = model.bairroComunidadeId;
        this.bairroComunidade = model.bairroComunidade;
        this.projetoOngId = model.projetoOngId;
        this.parecer = model.parecer;

        this.avaliacoes = model.avaliacoes;
        this.comunicacoes = model.comunicacoes;
        this.documentos = model.documentos;
        this.equipes = model.equipes;
        this.indicadores = model.indicadores;
        this.orcamentos = model.orcamentos;
        this.parceiros = model.parceiros;
        this.respostas = model.respostas;
    }
}