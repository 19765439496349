import { Empresa } from ".";

export class TipoDocumento {

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = "";

    constructor(model?: TipoDocumento){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.nome = model.nome;
    }
}