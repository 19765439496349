import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class EditalService extends Service{

  constructor(){
    super('edital');
  }

  Publicar(id: number){
    return http.post(`${this.GetNomeControle()}/${id}/publicar`);
  }

  EnviarRegulamento(id: number, nome: string, arquivo: any){
    let bodyFormData = new FormData();
    bodyFormData.append('arquivo', new Blob([arquivo], {type: 'application/pdf'}));

    return http.post(`${this.GetNomeControle()}/${id}/regulamento`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data' },
      params: {
        nome
      }
    })
  }

  DownloadRegulamento(id: number, nome: string){
    return http.get(`${this.GetNomeControle()}/${id}/regulamento`, {
      // responseType: 'blob',
      params:{
        nome
      }
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    })
  }

  VisualizarRegulamento(id: number, nome: string){
    return http.get(`${this.GetNomeControle()}/${id}/regulamento/visualizar`, {
      params:{
        nome
      }
    });      
  }

  DeletarRegulamento(id: number, nome: string){
    return http.delete(`${this.GetNomeControle()}/${id}/regulamento`, {
      params: {
        nome
      }
    })
  }

  GetByOngId(ongId: number){
    return http.get(`${this.GetNomeControle()}/Ong/${ongId}`);
  }

  DentroPrazo(){
    return http.get(`${this.GetNomeControle()}/DentroPrazo`);
  }

  HasProjeto(ongId: number){
    return http.get(`${this.GetNomeControle()}/HasProjeto`, {
      params:{
        ongId
      }
    });
  }

  TipoDocumento(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/TipoDocumento`);
  }

  TipoEdital(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Tipo`);
  }

}