import { Edital, Empreendedor } from ".";

export class EditalEmpreendedor {

    editalId: number = 0;
    edital!: Edital;
    empreendedorId: number = 0;
    empreendedor!: Empreendedor;

    constructor(model?: EditalEmpreendedor){

        if(!model)
            return;

        this.editalId = model.editalId;
        this.edital = model.edital;
        this.empreendedorId = model.empreendedorId;
        this.empreendedor = model.empreendedor;
    }
}