import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class ProjetoAvaliacaoService extends Service{

  constructor(){
    super('ProjetoAvaliacao');
  }

  ProjetoAvaliacao(projetoId: number, usuarioId: number){
    return http.get(`${this.GetNomeControle()}/Projeto/${projetoId}/Usuario/${usuarioId}`)
  }

  Reabrir(projetoId: number, usuarioId: number){
    return http.post(`${this.GetNomeControle()}/Projeto/${projetoId}/Usuario/${usuarioId}/Reabrir`)
  }
}